@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.title {
  font-weight: 400;
  margin-bottom: 24px;
}

.desc {
  font-weight: 400;
  letter-spacing: 0.2px;
  margin-bottom: 24px;
}

.stockTitle {
  font-weight: 700;
  font-size: 24px;
  font-stretch: condensed;
  margin-bottom: 16px;
}

.divider {
  margin: 24px 0px;
  height: 1px;
  width: 100%;
  background-color: colors.$grey;
}

.productContainer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  @media screen and (min-width: vars.$extra-extra-large) {
    grid-template-columns: repeat(2, minmax(490px, 1fr));
  }
}

.emptyRoot {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 32px;

  svg {
    margin-bottom: 32px;
  }

  h5 {
    font-size: 24px;
    font-weight: 400;
  }

  p {
    text-align: center;
    color: colors.$grey-default;
    max-width: 425px;
    margin-top: 12px;
    margin-bottom: 32px;
    letter-spacing: 0.2px;
  }

  a {
    text-decoration: none;
    button {
      padding: 0px 16px;
    }
  }
}

@keyframes loading {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

.loaderWrapper {
  height: 100px;
}

.placeholderNode {
  background: colors.$grey;
  fill: none;
  border-radius: 3px;
  color: transparent;
  animation-name: loading;
  animation-timing-function: ease-out;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  width: 100%;
  height: 100%;
}
