@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.root {
  display: flex;
  gap: 16px;
  padding: 16px;
  width: 100%;
  background-color: colors.$white;
  border-radius: 4px;
  flex-direction: column;
  @media screen and (min-width: vars.$medium) {
    flex-direction: row;
  }
}

.productCard {
  width: 100%;

  @media screen and (min-width: vars.$medium) {
    width: 45%;
  }
}

.status {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: vars.$medium) {
    width: 55%;
  }

  p {
    font-size: 16px;
    margin-bottom: 8px;
  }
}

.statusTitle {
  font-size: 16px;
  font-weight: 700;
  font-stretch: condensed;
  margin-bottom: 12px;
}

.subscribe {
  margin-top: 24px;
}

.action {
  margin-top: auto;
}

.stockWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 16px;
}

.stockStatus {
  display: flex;
  align-items: center;
  padding: 2px 8px;
  gap: 4px;
  border-radius: 4px;
  background-color: rgba(0, 191, 137, 0.15);
  font-size: 14px;
}

.outOfStock {
  background-color: rgba(100, 100, 100, 0.15);
}
