@use '../../styleguide/colors.scss';

.prices {
  display: flex;
  text-align: right;
  position: relative;

  span {
    font-weight: inherit;
  }
}

.leftPrices {
  text-align: left;
}

.oldPrice {
  position: absolute;
  top: -36px;
  margin-right: 15px;
  padding-top: 15px;
  color: #ff4b50;
  line-height: 100%;

  &:before {
    position: absolute;
    content: '';
    left: -2px;
    top: 22px;
    right: -2px;
    border-top: 2px solid #ff4b50;
  }
}

.currentPrice {
  line-height: 100%;
  font-weight: inherit;
}

.root {
  position: relative;
  line-height: 100%;
  display: flex;
}

.smallSymbol {
  font-size: 100%;
  line-height: 100%;
}

.oldPrice {
  .smallSymbol {
    top: initial;
    left: -8px;
  }
}

.noCurrency {
  margin-left: 0px;
}
